import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './PrayerBoxHeader.css'

export default () => {
    const data = useStaticQuery ( graphql`
    query PrayerBoxHeaderQuery {
      file(relativePath: {eq: "addressbook.jpg"}) {
        childImageSharp {
          fluid(maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
    return(
            <div className="header_Container">
                <BackgroundImage
                fluid={data.file.childImageSharp.fluid}
                className='prayerBoxHeader_Background'>
                    <div className='prayerBoxHeader_Title'>
                        <h1>禱 告 箱</h1>
                    </div>
                </BackgroundImage>
            </div>
    )
}