import { Form, Input, Button } from 'antd';
import React from 'react';
import './PrayerForm.css';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const validateMessages = {
  required: 'This is required!',
  types: {
    email: 'Please enter a validate email!',
    string: '${label} must between ${min} and ${max} characters',
  },
};

export default ()=>{


  const onFinish = values => {
    console.log(values);
  };

  return (
    <div className='FormWrapper'>
    <Form {...layout} name="nest-messages" onFinish={onFinish}>
      <Form.Item
        name={['user', 'name']}
        label="Name"
        rules={[
          {
            type: 'string',
          },
          {
            required: true,
            message: 'Name is required',
          },
          {
            min: 1,
            max: 20,
            message: 'Please input less than 20 characters',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['user', 'email']}
        label="Email"
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid email address'
          },
          {
            required: true,
            message: 'Email is required'
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['user', 'phonenumber']}
        label='Phone Number'
        rules={[
          {
            type: 'string',
          },
          {
            pattern: /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})\s*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+)\s*)?$/,
            message: 'Please enter a valid Phone Number',
          },
        ]}>
        <Input placeholder="123-456-7890"/>
      </Form.Item>
      <Form.Item 
        name={['user', 'Message']} 
        label="Message"
        rules={[
          {
            required: true,
            message: 'Please enter your message',
          },
          {
            min: 1,
            max: 500,
            message: 'Please input less than 500 characters'
          },
        ]}>
        <Input.TextArea autoSize={{ minRows: 10, maxRows: 10 }} />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </div>
  );
};